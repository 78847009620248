import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LoadingWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.darkBrown};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingContent = styled(motion.div)`
  text-align: center;
`;

const Name = styled(motion.h1)`
  font-size: clamp(40px, 10vw, 120px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.lightBrown};
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 20px;
`;

const TransitionCurve = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  transform-origin: bottom;
`;

const LoadingScreen = ({ onLoadingComplete }) => {
  return (
    <LoadingWrapper>
      <LoadingContent
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Name>
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Danny
          </motion.span>{' '}
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            Chen
          </motion.span>
        </Name>
      </LoadingContent>
      <TransitionCurve
        initial={{ scaleY: 0 }}
        animaate={{ scaleY: 1 }}
        transition={{
          duration: 1,
          delay: 2.5,
          ease: [0.76, 0, 0.24, 1],
        }}
        onAnimationComplete={onLoadingComplete}
      />
    </LoadingWrapper>
  );
};

export default LoadingScreen;
