import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { Lenis, useLenis } from '@studio-freight/react-lenis';
import GlobalStyle from './styles/GlobalStyle';
import HomePage from './views/HomePage';
import ProjectOne from './views/ProjectOne';
import ProjectTwo from './views/ProjectTwo';
import ProjectThree from './views/ProjectThree';
import CustomCursor from './components/CustomCursor';
import LoadingScreen from './components/LoadingScreen';
import NavMenu from './components/NavMenu';
import AvatarEasterEgg from './components/AvatarEasterEgg';
import { theme } from './theme';
import MusicPlayer from './components/MusicPlayer';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [showMusicPlayer, setShowMusicPlayer] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const lenis = useLenis();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => setShowMusicPlayer(true), 500);
    }, 3000);
  }, []);

  useEffect(() => {
    if (lenis) {
      lenis.on('scroll', ({ progress }) => {
        setScrollProgress(progress);
      });
    }
  }, [lenis]);

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    window.addEventListener('click', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);
    };
  }, []);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Lenis root>
          <div style={{ position: 'relative', minHeight: '100vh' }}>
            <GlobalStyle />
            <CustomCursor />
            <AvatarEasterEgg />
            <AnimatePresence mode="wait">
              {isLoading ? (
                <LoadingScreen key="loading" onLoadingComplete={() => setIsLoading(false)} />
              ) : (
                <>
                  <NavMenu scrollProgress={scrollProgress} isMuted={isMuted} toggleMute={toggleMute} />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/project-one" element={<ProjectOne />} />
                    <Route path="/project-two" element={<ProjectTwo />} />
                    <Route path="/project-three" element={<ProjectThree />} />
                  </Routes>
                </>
              )}
            </AnimatePresence>
            {showMusicPlayer && (
              <MusicPlayer
                isMuted={isMuted}
                toggleMute={toggleMute}
                hasInteracted={hasInteracted}
              />
            )}
          </div>
        </Lenis>
      </ThemeProvider>
    </Router>
  );
};

export default App;