export const projects = [
    {
      title: "AI-Powered Analytics Dashboard",
      description: "A cutting-edge analytics platform leveraging artificial intelligence to provide real-time insights and predictive analysis.",
      link: "/project-one",
      github: "https://github.com/yourusername/ai-analytics-dashboard",
      collaborators: [
        "/images/collaborator1.jpg",
        "/images/collaborator2.jpg",
        "/images/collaborator3.jpg"
      ],
      shortDescription: "Real-time AI-driven analytics for businesses",
      image: "/images/project1.jpg"
    },
    {
      title: "Blockchain-based Supply Chain Solution",
      description: "A decentralized application ensuring transparency and traceability in complex supply chain networks.",
      link: "/project-two",
      github: "https://github.com/yourusername/blockchain-supply-chain",
      collaborators: [
        "/images/collaborator4.jpg",
        "/images/collaborator5.jpg"
      ],
      shortDescription: "Transparent and secure supply chain management",
      image: "/images/project2.jpg"
    },
    {
      title: "Augmented Reality Education Platform",
      description: "An innovative AR app designed to enhance learning experiences through interactive 3D models and simulations.",
      link: "/project-three",
      github: "https://github.com/yourusername/ar-education-platform",
      collaborators: [
        "/images/collaborator6.jpg",
        "/images/collaborator7.jpg",
        "/images/collaborator8.jpg"
      ],
      shortDescription: "Immersive AR learning experiences for students",
      image: "/images/project3.jpg"
    }
  ];
  
  export const slideUp = {
    initial: { y: "100%" },
    open: (i) => ({
      y: "0%",
      transition: {duration: 0.5, delay: 0.02 * i}
    }),
    closed: {
      y: "100%",
      transition: {duration: 0.5}
    }
  };