import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import App from './App';
import './index.css';
import './global.scss';
const theme = {
  colors: {
    background: '#e5e5e0',
    text: '#1a1a1a',
    accent: '#a0988d',
    secondaryAccent: '#8c7e6e',
    lightBrown: '#e5e5e0',
    darkBrown: '#2c2620',
  },
  fonts: {
    main: '"Inter", sans-serif',
    heading: '"Playfair Display", serif',
  },
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
