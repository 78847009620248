export const theme = {
    colors: {
      background: '#e5e5e0',
      text: '#1a1a1a',
      accent: '#a0988d',
      secondaryAccent: '#8c7e6e',
      lightBrown: '#e5e5e0',
      darkBrown: '#2c2620',
    },
    fonts: {
      main: '"Inter", sans-serif',
      heading: '"Playfair Display", serif',
    },
  };
  