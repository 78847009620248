import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.lightBrown};
  color: ${({ theme }) => theme.colors.darkBrown};
  padding: 4rem 2rem;
`;

const GetInTouch = styled.h2`
  font-size: clamp(2rem, 8vw, 6rem);
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const FooterSectionDiv = styled.div`
  margin-bottom: 2rem;
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const FooterLink = styled(motion.a)`
  display: block;
  color: ${({ theme }) => theme.colors.darkBrown};
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 4rem;
  font-size: 0.9rem;
`;

const FooterSection = () => {
  return (
    <FooterWrapper>
      <GetInTouch>GET IN TOUCH</GetInTouch>
      <FooterContent>
        <FooterSectionDiv>
          <FooterTitle>Navigation</FooterTitle>
          {['Home', 'Services', 'Works', 'About', 'Contact'].map((item) => (
            <FooterLink
              key={item}
              href={`#${item.toLowerCase()}`}
              whileHover={{ x: 5 }}
              whileTap={{ scale: 0.95 }}
            >
              {item}
            </FooterLink>
          ))}
        </FooterSectionDiv>
        <FooterSectionDiv>
          <FooterTitle>Socials</FooterTitle>
          {['LinkedIn', 'YouTube', 'Instagram', 'Bento', 'GitHub'].map((item) => (
            <FooterLink
              key={item}
              href={`#${item.toLowerCase()}`}
              whileHover={{ x: 5 }}
              whileTap={{ scale: 0.95 }}
            >
              {item}
            </FooterLink>
          ))}
        </FooterSectionDiv>
        <FooterSectionDiv>
          <FooterTitle>Resources</FooterTitle>
          {['PillarStack', 'Figma Templates', 'Monthly Newsletter'].map((item) => (
            <FooterLink
              key={item}
              href={`#${item.toLowerCase().replace(' ', '-')}`}
              whileHover={{ x: 5 }}
              whileTap={{ scale: 0.95 }}
            >
              {item}
            </FooterLink>
          ))}
        </FooterSectionDiv>
      </FooterContent>
      <Copyright>
        © 2024 DANNY CHEN
      </Copyright>
    </FooterWrapper>
  );
};

export default FooterSection;