import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import HeroSection from '../components/HeroSection';
import FooterSection from '../components/FooterSection';
import NavMenu from '../components/NavMenu';
import MusicPlayer from '../components/MusicPlayer';
import AvatarEasterEgg from '../components/AvatarEasterEgg';
import ProjectCards from '../components/ProjectCards';
import { projects } from '../data';

const HomePageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightBrown};
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const ProjectsSection = styled(motion.div)`
  background-color: #8c7e6e;
  position: relative;
  overflow: hidden;
`;

const HeroWrapper = styled(motion.div)`
  position: relative;
  z-index: 1;
`;

const HomePage = () => {
  const [isMuted, setIsMuted] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const projectsSectionRef = useRef(null);
  const heroSectionRef = useRef(null);

  const { scrollY } = useViewportScroll();
  const heroY = useTransform(scrollY, [0, 1000], [0, 500]);
  const heroOpacity = useTransform(scrollY, [0, 500], [1, 0]);
  const projectsY = useTransform(scrollY, [0, 1000], [1000, 0]);

  const toggleMute = () => {
    setIsMuted(prevIsMuted => !prevIsMuted);
  };

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    window.addEventListener('click', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);
    };
  }, []);

  return (
    <HomePageWrapper>
      <ContentWrapper>
        <HeroWrapper ref={heroSectionRef} style={{ y: heroY, opacity: heroOpacity }}>
          <HeroSection />
        </HeroWrapper>
        
        <ProjectsSection ref={projectsSectionRef} style={{ y: projectsY }}>
          <ProjectCards projects={projects} />
        </ProjectsSection>

        <FooterSection />
      </ContentWrapper>
      <NavMenu isMuted={isMuted} toggleMute={toggleMute} />
      <MusicPlayer isMuted={isMuted} toggleMute={toggleMute} hasInteracted={hasInteracted} />
      <AvatarEasterEgg />
    </HomePageWrapper>
  );
};

export default HomePage;