import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Linkedin, Github, Twitter, Instagram, ChevronDown, ChevronUp } from 'lucide-react';
import confetti from 'canvas-confetti';

const AvatarWrapper = styled(motion.div)`
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled(motion.img)`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
`;

const MessageBubble = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.background};
  padding: 25px 35px;
  border-radius: 20px;
  font-size: 20px;
  margin-top: 10px;
  white-space: nowrap;
`;

const ToggleButton = styled(motion.button)`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const IconsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const SocialIcon = styled(motion.a)`
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.accent};
  &:hover {
    color: ${({ theme }) => theme.colors.background};
  }
`;

const ClickMeBox = styled(motion.div)`
  position: absolute;
  right: -80px;
  top: 20%;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.background};
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 13px;
`;

const avatarImages = [
  '/images/dannypfp.jpg',
  '/images/grainybg.jpg',
  '/images/dannypfp2.jpg',
];

const messages = [
  'Hey',
  'HEY!!!',
  'Stop clicking me >:(',
  'I will tell Danny this!',
  'I will not forgive you >:C',
  "I'm ignoring you :(",
];

const socialIcons = [
  { Icon: Linkedin, url: 'https://www.linkedin.com/in/dannywchen' },
  { Icon: Github, url: 'https://github.com/dannywchen' },
  { Icon: Twitter, url: 'https://x.com/dannywchen' },
  { Icon: Instagram, url: 'https://www.instagram.com/dannywchen/' }
];

const AvatarEasterEgg = () => {
  const [currentAvatar, setCurrentAvatar] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [showIcons, setShowIcons] = useState(true);
  const [showClickMe, setShowClickMe] = useState(true);
  const avatarRef = useRef(null);

  const handleClick = () => {
    setCurrentAvatar((prev) => (prev + 1) % avatarImages.length);
    setClickCount((prev) => prev + 1);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
    setShowClickMe(false);

    if (avatarRef.current) {
      const rect = avatarRef.current.getBoundingClientRect();
      const x = (rect.left + rect.right) / 2 / window.innerWidth;
      const y = (rect.top + rect.bottom) / 2 / window.innerHeight;
      
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { x, y }
      });
    }
  };

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  return (
    <AvatarWrapper>
      <Avatar
        ref={avatarRef}
        src={avatarImages[currentAvatar]}
        alt="Avatar"
        onClick={handleClick}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      />
      <AnimatePresence>
        {showMessage && (
          <MessageBubble
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {messages[Math.min(clickCount, messages.length - 1)]}
          </MessageBubble>
        )}
      </AnimatePresence>
      {showClickMe && (
        <ClickMeBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          Click me!
        </ClickMeBox>
      )}
      <ToggleButton onClick={toggleIcons}>
        {showIcons ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </ToggleButton>
      <AnimatePresence>
        {showIcons && (
          <IconsContainer
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {socialIcons.map(({ Icon, url }, index) => (
              <SocialIcon
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Icon size={30} />
              </SocialIcon>
            ))}
          </IconsContainer>
        )}
      </AnimatePresence>
    </AvatarWrapper>
  );
};

export default AvatarEasterEgg;