import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ProjectWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBrown};
  color: ${({ theme }) => theme.colors.lightBrown};
  min-height: 100vh;
  padding: 2rem;
`;

const BackButton = styled(Link)`
  position: fixed;
  top: 2rem;
  left: 2rem;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.darkBrown};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  z-index: 10;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
`;

const Sidebar = styled.div`
  position: sticky;
  top: 2rem;
  height: calc(100vh - 4rem);
  overflow-y: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.accent};
  margin-bottom: 1rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const ProjectOne = () => {
  return (
    <ProjectWrapper>
      <BackButton to="/">Go Back</BackButton>
      <ContentWrapper>
        <Sidebar>
          <SubTitle>Table of Contents</SubTitle>
          <List>
            <ListItem>Introduction</ListItem>
            <ListItem>Project Goals</ListItem>
            <ListItem>Technologies Used</ListItem>
            <ListItem>Challenges</ListItem>
            <ListItem>Results</ListItem>
          </List>
        </Sidebar>
        <MainContent>
          <Title>AI-Powered Analytics Dashboard</Title>
          <Image src="/images/dannypfp.jpg" alt="Project One" />
          <SubTitle>Introduction</SubTitle>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Text>
          <SubTitle>Project Goals</SubTitle>
          <Text>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </Text>
          <SubTitle>Technologies Used</SubTitle>
          <List>
            <ListItem>React</ListItem>
            <ListItem>Node.js</ListItem>
            <ListItem>TensorFlow</ListItem>
            <ListItem>AWS</ListItem>
          </List>
          <SubTitle>Challenges</SubTitle>
          <Text>
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </Text>
          <SubTitle>Results</SubTitle>
          <Text>
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </MainContent>
      </ContentWrapper>
    </ProjectWrapper>
  );
};

export default ProjectOne;