import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useTransform, useScroll, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const CardContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.lightBrown};
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBrown};
  font-family: ${({ theme }) => theme.fonts.main};
`;

const LeftSide = styled(motion.div)`
  width: 30%;
  height: 30vh;
  position: fixed;
  top: 15vw;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  opacity: 0;
`;

const RightSide = styled.div`
  width: 70%;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30rem 0 0 0;
`;

const ProjectNumber = styled(motion.h1)`
  font-size: 20vw;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const ProjectTitle = styled(motion.h2)`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const CollaboratorsContainer = styled(motion.div)`
  display: flex;
  margin-bottom: 1rem;
  z-index: 1;
`;

const CollaboratorAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const ProjectDescription = styled(motion.p)`
  font-size: 1.2rem;
  line-height: 1.5;
  z-index: 1;
`;

const BentoBox = styled(motion.div)`
  width: 90%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.secondaryAccent};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 30vh;
`;

const BentoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const BentoContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 2rem;
  width: 100%;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
`;

const BentoTitle = styled(motion.h3)`
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
`;

const ShortDescription = styled(motion.p)`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 1rem;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
`;

const slideUp = {
  initial: { y: "100%", opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.5, ease: [0.6, 0.01, 0, 0.9] } },
  exit: { y: "-100%", opacity: 0, transition: { duration: 0.5, ease: [0.6, 0.01, 0, 0.9] } }
};

const ProjectCards = ({ projects }) => {
  const containerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const leftSideOpacity = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(latest => {
      const index = Math.min(Math.floor(latest * projects.length), projects.length - 1);
      if (index !== activeIndex) {
        setActiveIndex(index);
      }
    });

    return () => unsubscribe();
  }, [scrollYProgress, activeIndex, projects.length]);

  if (!projects || projects.length === 0) {
    return <div>No projects available</div>;
  }

  return (
    <CardContainer ref={containerRef}>
      <LeftSide style={{ opacity: leftSideOpacity }}>
        <AnimatePresence mode="wait">
          <motion.div 
            key={activeIndex} 
            initial="initial" 
            animate="animate" 
            exit="exit" 
            variants={{
              initial: { opacity: 0, y: 50 },
              animate: { opacity: 1, y: 0 },
              exit: { opacity: 0, y: -50 }
            }}
            transition={{ duration: 0.3 }}
            style={{ position: 'relative' }}
          >
            <ProjectNumber variants={slideUp}>{activeIndex + 1}</ProjectNumber>
            <ProjectTitle>
              {projects[activeIndex].title.split("").map((letter, i) => (
                <motion.span
                  key={i}
                  style={{ display: 'inline-block', position: 'relative', overflow: 'hidden' }}
                >
                  <motion.span
                    variants={slideUp}
                    custom={i}
                    initial="initial"
                    animate="animate"
                    style={{ display: 'inline-block' }}
                  >
                    {letter}
                  </motion.span>
                </motion.span>
              ))}
            </ProjectTitle>
            <CollaboratorsContainer variants={slideUp}>
              {projects[activeIndex].collaborators && projects[activeIndex].collaborators.map((collaborator, i) => (
                <CollaboratorAvatar key={i} src={collaborator} alt="Collaborator" />
              ))}
            </CollaboratorsContainer>
            <ProjectDescription>
              {projects[activeIndex].description.split(" ").map((word, i) => (
                <motion.span
                  key={i}
                  style={{ display: 'inline-block', position: 'relative', overflow: 'hidden', marginRight: '0.25em' }}
                >
                  <motion.span
                    variants={slideUp}
                    custom={i}
                    initial="initial"
                    animate="animate"
                    style={{ display: 'inline-block' }}
                  >
                    {word}
                  </motion.span>
                </motion.span>
              ))}
            </ProjectDescription>
          </motion.div>
        </AnimatePresence>
      </LeftSide>
      <RightSide>
        {projects.map((project, index) => (
          <Link key={index} to={project.link} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
            <BentoBox
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onViewportEnter={() => setActiveIndex(index)}
            >
              <BentoImage src={project.image} alt={project.title} />
              <BentoContent>
                <BentoTitle variants={slideUp} initial="initial" animate="animate" exit="exit">{project.title}</BentoTitle>
                <ShortDescription variants={slideUp} initial="initial" animate="animate" exit="exit">{project.shortDescription}</ShortDescription>
                <TagsContainer>
                  {project.tags && project.tags.map((tag, i) => (
                    <Tag key={i}>{tag}</Tag>
                  ))}
                </TagsContainer>
              </BentoContent>
            </BentoBox>
          </Link>
        ))}
      </RightSide>
    </CardContainer>
  );
};

export default ProjectCards;