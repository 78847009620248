import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.mask {
  position: relative;
  overflow: hidden;
  display: inline-flex;
}

@keyframes lineAnimation {
  from {
    width: 0;
  }
  to {
    width: 300px;
  }
}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.heading};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  .custom-cursor {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.accent};
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease, width 0.2s ease, height 0.2s ease;
    z-index: 10000;

    &.hovered {
      width: 40px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors.secondaryAccent};
    }
  }
`;

export default GlobalStyle;
