import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Howl } from 'howler';
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';

const flowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const PlayerContainer = styled(motion.div)`
  position: fixed;
  top: 50px;
  right: 50px;
  background: linear-gradient(45deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
    right: 5%;
    top: 10px;
  }
`;

const MinimizedPlayer = styled(motion.div)`
  position: fixed;
  top: 50px;
  right: 50px;
  background: linear-gradient(45deg, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
  border-radius: 30px;
  padding: 10px 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #6a4f4b;
  display: flex;
  align-items: center;
  gap: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ffdde1, #ee9ca7, #ffdde1);
    background-size: 200% 200%;
    animation: ${flowAnimation} 15s ease infinite;
    opacity: 0.2;
    border-radius: 30px;
    z-index: -1;
  }

  @media (max-width: 768px) {
    right: 10px;
    top: 10px;
  }
`;

const AlbumArt = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  margin-right: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const TrackInfo = styled.div`
  flex-grow: 1;
`;

const TrackName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #6a4f4b;
  font-weight: 600;
`;

const ArtistName = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #9a817d;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #6a4f4b;
  transition: color 0.3s ease;

  &:hover {
    color: #ee9ca7;
  }
`;

const ProgressBar = styled.input`
  width: 100%;
  margin-top: 15px;
  -webkit-appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: #d7ccc8;
    border-radius: 2px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ee9ca7;
    margin-top: -6px;
    cursor: pointer;
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #6a4f4b;
`;


const TimeDisplay = styled.div`
  font-size: 12px;
  color: #9a817d;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const tracks = [
  { title: 'Dreamy', artist: 'Danny Moosic Yeah', file: '/music/dannymusic1.mp3', avatar: '/images/musicpfp1.webp' },
  { title: 'Sleepy', artist: 'Danny Moosic Good', file: '/music/dannymusic2.mp3', avatar: '/images/musicpfp2.jpg' },
  { title: 'Jazzz', artist: 'Danny Moosic Premium', file: '/music/dannymusic3.mp3', avatar: '/images/musicpfp3.jpg' },
];

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const MusicPlayer = ({ isMuted, toggleMute }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const soundRef = useRef(null);

  const nextTrack = useCallback(() => {
    setCurrentTrack((prevTrack) => (prevTrack + 1) % tracks.length);
    setIsPlaying(false);
  }, []);

  const initializeAudio = useCallback(() => {
    if (soundRef.current) {
      soundRef.current.unload();
    }
    soundRef.current = new Howl({
      src: [tracks[currentTrack].file],
      autoplay: false,
      loop: false,
      volume: 1,
      onend: nextTrack,
      onload: () => {
        setDuration(soundRef.current.duration());
      },
    });
  }, [currentTrack, nextTrack]);

  useEffect(() => {
    initializeAudio();
    return () => {
      if (soundRef.current) {
        soundRef.current.unload();
      }
    };
  }, [initializeAudio]);

  const togglePlay = useCallback(() => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    if (soundRef.current.playing()) {
      soundRef.current.pause();
      setIsPlaying(false);
    } else {
      soundRef.current.play();
      setIsPlaying(true);
    }
  }, [hasInteracted]);

  const prevTrack = useCallback(() => {
    setCurrentTrack((prevTrack) => (prevTrack - 1 + tracks.length) % tracks.length);
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const updateProgress = () => {
      if (soundRef.current && isPlaying) {
        setProgress(soundRef.current.seek());
        requestAnimationFrame(updateProgress);
      }
    };

    if (isPlaying) {
      updateProgress();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (hasInteracted && !isPlaying) {
      togglePlay();
    }
  }, [hasInteracted, isPlaying, togglePlay]);

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.mute(isMuted);
    }
  }, [isMuted]);

  const handleProgressChange = (e) => {
    const value = parseFloat(e.target.value);
    soundRef.current.seek(value);
    setProgress(value);
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <AnimatePresence>
      {isMinimized ? (
        <MinimizedPlayer
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          onClick={toggleMinimize}
        >
          <IoMdArrowDropdown />
          Playing Moosic
        </MinimizedPlayer>
      ) : (
        <PlayerContainer
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
        >
          <ToggleButton onClick={toggleMinimize}>
            <IoMdArrowDropup />
          </ToggleButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AlbumArt src={tracks[currentTrack].avatar} onClick={toggleDetails} />
            <TrackInfo>
              <TrackName>{tracks[currentTrack].title}</TrackName>
              <ArtistName>{tracks[currentTrack].artist}</ArtistName>
            </TrackInfo>
          </div>
          <ProgressBar
            type="range"
            min={0}
            max={duration}
            value={progress}
            onChange={handleProgressChange}
          />
          <TimeDisplay>
            <span>{formatTime(progress)}</span>
            <span>{formatTime(duration)}</span>
          </TimeDisplay>
          <Controls>
            <ControlButton onClick={prevTrack}><FaStepBackward /></ControlButton>
            <ControlButton onClick={togglePlay}>
              {isPlaying ? <FaPause /> : <FaPlay />}
            </ControlButton>
            <ControlButton onClick={nextTrack}><FaStepForward /></ControlButton>
            <ControlButton onClick={toggleMute}>
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </ControlButton>
          </Controls>
          {showDetails && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              <p>
                https://uppbeat.io/t/auv/second-stop-cafe | 
                https://uppbeat.io/t/fortune-cookie/golden-summer-lenny-b |
                https://uppbeat.io/t/pecan-pie/lo-fi-rainbow
              </p>
            </motion.div>
          )}
        </PlayerContainer>
      )}
    </AnimatePresence>
  );
};

export default MusicPlayer;