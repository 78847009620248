import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaLightbulb, FaCamera, FaTwitter, FaGithub, FaEnvelope, FaChevronDown, FaMoon, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

const NavigationBar = styled(motion.nav)`
  position: fixed;
  bottom: 1200px;
  left: 36.5%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding: 15px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const NavItemWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 35%;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 6px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    margin: 0 4px;
  }
`;

const NavItemIcon = styled(motion.div)`
  color: rgba(255, 255, 255, 0.8);
  svg {
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

const HoverBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
`;

const navItems = [
  { icon: FaHome, label: 'Home' },
  { icon: FaLightbulb, label: 'Projects' },
  { icon: FaCamera, label: 'Photos' },
  { icon: FaTwitter, label: 'Twitter' },
  { icon: FaGithub, label: 'GitHub' },
  { icon: FaEnvelope, label: 'Contact' },
  { icon: FaChevronDown, label: 'More' },
  { icon: FaMoon, label: 'Dark Mode' },
];

const NavMenu = ({ isMuted, toggleMute }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <NavigationBar
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, 0.01, 0.05, 0.95] }}
    >
      {navItems.map((item, index) => (
        <NavItemWrapper
          key={index}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <NavItemIcon
            animate={{
              scale: hoveredIndex === index ? 1.2 : 1,
              color: hoveredIndex === index ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)',
            }}
            transition={{ duration: 0.2 }}
          >
            <item.icon />
          </NavItemIcon>
          <AnimatePresence>
            {hoveredIndex === index && (
              <HoverBackground
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
            )}
          </AnimatePresence>
        </NavItemWrapper>
      ))}
      <NavItemWrapper
        onMouseEnter={() => setHoveredIndex(navItems.length)}
        onMouseLeave={() => setHoveredIndex(null)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={toggleMute}
      >
        <NavItemIcon
          animate={{
            scale: hoveredIndex === navItems.length ? 1.2 : 1,
            color: hoveredIndex === navItems.length ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)',
          }}
          transition={{ duration: 0.2 }}
        >
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </NavItemIcon>
        <AnimatePresence>
          {hoveredIndex === navItems.length && (
            <HoverBackground
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            />
          )}
        </AnimatePresence>
      </NavItemWrapper>
    </NavigationBar>
  );
};

export default NavMenu;