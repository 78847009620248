import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';

const HeroWrapper = styled.section`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  position: relative;
  overflow: hidden;
  padding: 0 6rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  z-index: 2;
`;

const Title = styled(motion.h1)`
  font-size: clamp(48px, 8vw, 80px);
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkBrown};
  margin-bottom: 1rem;
`;

const Description = styled(motion.p)`
  font-size: clamp(24px, 3vw, 36px);
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.darkBrown};
  max-width: 780px;
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  width: 35%;
  height: 60vh;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
`;

const MovingBanner = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const BannerText = styled.span`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.accent};
  padding: 20px 0;
  opacity: 0.7;
`;

const slideUp = {
  initial: { y: "100%" },
  animate: { y: 0, transition: { duration: 0.5, ease: [0.6, 0.01, 0, 0.9] } },
  exit: { y: "-100%", transition: { duration: 0.5, ease: [0.6, 0.01, 0, 0.9] } }
};

const HeroSection = () => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const isInViewTitle = useInView(titleRef, { once: true });
  const isInViewDescription = useInView(descriptionRef, { once: true });
  const isInViewImage = useInView(imageRef, { once: true });

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start']
  });

  const bannerY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);

  return (
    <HeroWrapper ref={containerRef}>
      <MovingBanner style={{ y: bannerY }}>
        {Array(10).fill('DANNY CHEN').map((text, index) => (
          <BannerText key={index}>{text}</BannerText>
        ))}
      </MovingBanner>
      <ContentContainer>
        <Title ref={titleRef}>
          {
            "DANNY CHEN".split("").map((letter, i) => (
              <motion.span
                key={i}
                style={{ display: 'inline-block', position: 'relative', overflow: 'hidden' }}
              >
                <motion.span
                  variants={slideUp}
                  custom={i}
                  initial="initial"
                  animate={isInViewTitle ? "animate" : "initial"}
                  style={{ display: 'inline-block' }}
                >
                  {letter}
                </motion.span>
              </motion.span>
            ))
          }
        </Title>
        <Description
          ref={descriptionRef}
          variants={slideUp}
          initial="initial"
          animate={isInViewDescription ? "animate" : "initial"}
        >
          A full stack developer who happens to make startups.
        </Description>
      </ContentContainer>
      <ImageContainer
        ref={imageRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isInViewImage ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <StyledImage src="/images/dannypfp.jpg" alt="Danny Chen" />
      </ImageContainer>
    </HeroWrapper>
  );
};

export default HeroSection;