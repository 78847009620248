import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useMotionValue, useSpring, AnimatePresence } from 'framer-motion';

const CursorWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
`;

const CursorDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 50%;
`;

const CursorCircle = styled(motion.div)`
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.colors.accent};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ViewText = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.accent};
  font-size: 14px;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 8px;
  border-radius: 12px;
`;

const TextCursor = styled(motion.div)`
  width: 2px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.accent};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const CustomCursor = () => {
  const [cursorType, setCursorType] = useState('default');
  const [elementDimensions, setElementDimensions] = useState({ width: 0, height: 0 });
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 25, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX);
      cursorY.set(e.clientY);
    };

    window.addEventListener('mousemove', moveCursor);

    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, [cursorX, cursorY]);

  useEffect(() => {
    const handleMouseEnter = (e) => {
      const target = e.target;
      if (target.tagName.toLowerCase() === 'button') {
        setCursorType('button');
        setElementDimensions({
          width: target.offsetWidth,
          height: target.offsetHeight
        });
      } else if (target.tagName.toLowerCase() === 'img') {
        setCursorType('image');
      } else if (
        target.tagName.toLowerCase() === 'p' ||
        target.tagName.toLowerCase() === 'h1' ||
        target.tagName.toLowerCase() === 'h2' ||
        target.tagName.toLowerCase() === 'h3' ||
        target.tagName.toLowerCase() === 'span'
      ) {
        setCursorType('text');
        const circleSize = Math.max(target.offsetWidth, target.offsetHeight);
        setElementDimensions({
          width: circleSize,
          height: circleSize
        });
      } else {
        setCursorType('default');
      }
    };

    const handleMouseLeave = () => {
      setCursorType('default');
    };

    document.addEventListener('mouseover', handleMouseEnter);
    document.addEventListener('mouseout', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseover', handleMouseEnter);
      document.removeEventListener('mouseout', handleMouseLeave);
    };
  }, []);

  return (
    <>
      <style>
        {`
          * {
            cursor: none !important;
          }
        `}
      </style>
      <CursorWrapper style={{ x: cursorXSpring, y: cursorYSpring }}>
        <AnimatePresence>
          {cursorType === 'text' ? (
            <CursorCircle
              key="text-cursor"
              animate={{
                width: elementDimensions.width,
                height: elementDimensions.height,
                opacity: 0.5,
              }}
              exit={{
                width: 40,
                height: 40,
                opacity: 1,
              }}
              transition={{ duration: 0.3 }}
            >
              <TextCursor
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
            </CursorCircle>
          ) : (
            <CursorDot
              key="dot-cursor"
              animate={{
                scale: cursorType === 'button' ? elementDimensions.width / 8 : 1,
              }}
              transition={{ duration: 0.2 }}
            />
          )}
          {cursorType !== 'text' && (
            <CursorCircle
              key="circle-cursor"
              animate={{
                width: cursorType === 'button' ? elementDimensions.width : 40,
                height: cursorType === 'button' ? elementDimensions.height : 40,
                opacity: cursorType === 'default' ? 1 : 0.5,
              }}
              transition={{ duration: 0.3 }}
            />
          )}
          {cursorType === 'image' && (
            <ViewText
              key="view-text"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
            >
              view →
            </ViewText>
          )}
        </AnimatePresence>
      </CursorWrapper>
    </>
  );
};

export default CustomCursor;
